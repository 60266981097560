<template>
  <div id="section3">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="4" sm="12" class="text-center">
              <b-img :src="payImg"/>
            </b-col>
            <b-col md="8" sm="12">
              <br>
              <h2>
                {{$t("payTitulo")}}
              </h2>
              <h3>
                {{$t("payTtl")}}
              </h3>
              <p >
                {{$t("payText")}}
              </p>
              <div>
                <a :href="payUrl" class="btn buttonTurqueza">
                  {{$t("payBtn")}}
                </a>
              </div>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "GLOP"
    , "payTtl": "Un software tpv para cada tipo de establecimiento."
    , "payText": "Un software que te permite agilizar, automatizar y mejorar la gestión diaria de un establecimiento y para ello se necesitan funciones muy especificas según el sector donde se use."
    , "payBtn": "MÁS INFORMACIÓN"
  },
  "es":{
    "payTitulo": "GLOP"
    , "payTtl": "Un software tpv para cada tipo de establecimiento."
    , "payText": "Un software que te permite agilizar, automatizar y mejorar la gestión diaria de un establecimiento y para ello se necesitan funciones muy especificas según el sector donde se use."
    , "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/partners/glop.png")
      , payUrl: "#"
    };
  }
};
</script>
