<template>
  <div id="section6">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="4" sm="12" class="text-center" order-sm="1" order-md="2">
              <b-img :src="payImg"/>
            </b-col>
            <b-col md="8" sm="12" order-sm="2" order-md="1" class="text-right">
              <br>
              <h2>
                {{$t("payTitulo")}}
              </h2>
              <h3>
                {{$t("payTtl")}}
              </h3>
              <p >
                {{$t("payText")}}
              </p>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Informática El Corte Inglés"
    , "payTtl": "Informática El Corte Inglés es una compañía del Grupo El Corte Inglés especializada en la prestación de soluciones de tecnología, soluciones de negocio y servicios gestionados. Aportan innovación a todos los sectores empresariales y Administraciones Públicas a través de tecnología orientada a modelos de gestión y modelos de negocio."
    , "payText": " "
    , "payBtn": " "
  },
  "es":{
    "payTitulo": "Informática El Corte Inglés"
    , "payTtl": "Informática El Corte Inglés es una compañía del Grupo El Corte Inglés especializada en la prestación de soluciones de tecnología, soluciones de negocio y servicios gestionados. Aportan innovación a todos los sectores empresariales y Administraciones Públicas a través de tecnología orientada a modelos de gestión y modelos de negocio."
    , "payText": " "
    , "payBtn": " "
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/partners/elcorteingles.png")
      , payUrl: ""
    };
  }
};
</script>
