<template>
  <div id="section10">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="4" sm="12" class="text-center" order-sm="1" order-md="2">
              <b-img :src="payImg"/>
            </b-col>
            <b-col md="8" sm="12" order-sm="2" order-md="1" class="text-right">
              <br>
              <h2>
                {{$t("payTitulo")}}
              </h2>
              <h3>
                {{$t("payTtl")}}
              </h3>
              <p >
                {{$t("payText")}}
              </p>
              <div>
                <a :href="payUrl" class="btn buttonTurqueza">
                  {{$t("payBtn")}}
                </a>
              </div>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Paynopain"
    , "payTtl": "Empresa Fintech dedicada al desarrollo de servicios y plataformas bajo demanda, que cuenta en su portfolio con soluciones 360º como easyGOband o Change-it dedicadas a la gestión integral de eventos y servicios así como a la promoción y fidelización de comercios de todo tipo. Todo ello acompañado de apps nativas, webs e integración total con marca blanca."
    , "payText": " "
    , "payBtn": "MÁS INFORMACIÓN"
  },
  "es":{
    "payTitulo": "Paynopain"
    , "payTtl": "Empresa Fintech dedicada al desarrollo de servicios y plataformas bajo demanda, que cuenta en su portfolio con soluciones 360º como easyGOband o Change-it dedicadas a la gestión integral de eventos y servicios así como a la promoción y fidelización de comercios de todo tipo. Todo ello acompañado de apps nativas, webs e integración total con marca blanca."
    , "payText": " "
    , "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/partners/paynopain.png")
      , payUrl: "#"
    };
  }
};
</script>
